:root {
    --purple: #7161ba;
    --green: #7fc128;
    --black: #3b3a40;
    --grey: #eeedf2;
    --hover: #000000;
    --bgGray: #e5e5e5;
    --bgWhite: #f5f5f7;
    --darkBlue: #4e4693;
    --red: #ee6955;
}
.MuiSvgIcon-root {
    height: 18px !important;
    width: 18px !important;
}

.Mui-checked {
    color: var(--purple) !important;
}

.MuiPaper-root,
.MuiDialog-paper {
    border-radius: 16px !important;
    overflow: unset !important;
    max-width: unset !important;
    max-height: unset !important;
}
.MuiFormGroup-root {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
}
.MuiTypography-root {
    font-size: 14px !important;
}
.text-error {
    color: var(--red);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
}
.mb-16 {
    margin-bottom: 16px;
}

.flex-row {
    display: flex;
    align-items: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.flex-row-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-row-centerX {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-row-space-end {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.text-limit-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}
.none:not(:first-child) {
    display: none;
}
.btn-green {
    padding: 8px 16px;
    border-radius: 16px;
    background-color: var(--green);
    color: #fff;
    font-weight: bold;
    width: max-content;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 auto;
    margin-top: 16px;
    user-select: none;
    p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }
}
.scroll {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
}
.w-50 {
    width: 50%;
}
.dots {
    padding: 5px;
    p {
        width: 6px;
        height: 6px;
        background-color: var(--purple);
        border-radius: 100px;
        overflow: hidden;
        flex-shrink: 0;
    }
}
.gap-row-4 {
    gap: 0 4px;
}
.gap-row-16 {
    gap: 0 16px;
}
.color-purple {
    color: var(--purple);
}

.infinite-scroll-component {
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(132, 132, 132, 0.574);
        border-radius: 100px;
        overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(120, 120, 120, 0.455);
        border-radius: 100px;
        overflow: hidden;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .infinite-scroll-component {
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(125, 125, 125, 0.583);
        }
    }
}

// animation loading
.lds-dual-ring {
    display: inline-block;
    width: 16px;
    height: 16px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    // margin: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// service list
.service__list {
    display: flex;
    flex-direction: column;
    .service__item {
        display: flex;
        gap: 0 8px;
        &:not(:first-child) {
            padding-top: 16px;
        }
        &:not(:last-child) {
            padding-bottom: 16px;
        }
        &:not(:last-child) {
            border-bottom: 1px solid rgba(163, 162, 162, 0.13);
        }
        .service-item__img {
            width: 100px;
            height: 100px;
            border-radius: 16px;
            overflow: hidden;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .service-item__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 8px 0 0 8px;
            gap: 2px 0;
            .item-name {
                font-size: 16px;
                font-weight: bold;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .item-price {
                font-weight: bold;
            }
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        .service__item {
            padding: 0;
            .item__content {
                justify-content: center;
                gap: 4px 0;
            }
        }
    }
}
