// MIXINS
@mixin tablet {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        @content;
    }
}
.home {
    &-head__wrap {
        background-color: var(--bgWhite);
    }
    &-head {
        h1 {
            font-size: 16px;
            color: var(--purple);
        }
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
    }
    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__img {
            margin-top: 32px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            height: 100px;
            width: 100px;
            overflow: hidden;
            border-radius: 100px;
            flex-shrink: 0;
            margin-bottom: 16px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__title {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 8px 0;
            h2 {
                font-family: Quicksand;
                font-size: 16px;
                font-weight: 400;
                color: var(--purple);
                font-weight: bold;
                // color: white;
            }
        }
        &__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px 0px;
            margin: 0 auto;
            margin: 24px 0;
        }

        &__option {
            margin-top: 24px;
            width: 33.33%;
            display: flex;
            flex-direction: column;
            gap: 16px 0;

            .option-item {
                outline: none;
                height: 100%;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 20px 16px 24px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                .option-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: var(--purple);
                    filter: opacity(70%);
                    -webkit-filter: opacity(70%);
                    -moz-filter: opacity(70%);
                    z-index: 2;
                }
                p {
                    font-size: 16px;
                    color: white;
                    z-index: 3;
                    font-weight: 600;
                }
                img {
                    z-index: 3;
                }
            }
        }
    }
    @include tablet {
        &-content {
            height: calc(100vh - 350px);

            &__option {
                width: 50%;
            }
        }
    }
    @include mobile {
        &-head {
            height: 50px;
            &__logo {
                width: 80px;
                height: unset;
            }
            &__logout {
                padding: unset;
            }
        }
        &-content {
            &__title {
                max-width: 320px;
                h3 {
                    display: none;
                }
                h2 {
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0 auto;
                    // color: var(--purple);
                    line-height: 1.5;
                }
            }
            &__wrap {
                width: 100%;
                margin-top: 16px;
            }
            &__number {
                width: 100%;
                .number-input {
                    input {
                        font-size: 14px;
                    }
                }
            }
            &__option {
                width: 100%;
                .option-item {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Dialog OTP
.dialog-OTP {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--bgWhite);
    padding: 0px 16px 0 16px;

    .dialog-OTP__wrap {
        max-width: 400px;
        // width: 50%;
        margin: 0 auto;
    }
    .dialog-OTP__img {
        width: 200px;
        overflow: hidden;
        flex-shrink: 0;
        margin: 0 auto;
        margin-bottom: 24px;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dialog-OTP__content {
        text-align: center;

        .item-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 2;
        }
        .item-desc {
            font-size: 14px;
            font-weight: bold;
        }
        .number-input {
            margin-top: 16px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            user-select: none;
            input {
                line-height: normal;
                letter-spacing: 3px;
                font-size: 14px;
                width: 100%;
                font-weight: bold;
                padding: 16px 24px;
                border-radius: 8px;
                outline: none;
                color: var(--purple);
                border: 2px solid rgb(128, 128, 128);
                text-align: center;
                &::-webkit-input-placeholder {
                    font-weight: bold;
                    font-size: 14px;
                    color: rgb(128, 128, 128);
                }
                &:focus {
                    border-color: var(--purple);
                    -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
                    box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
                }
            }
            input::-webkit-input-placeholder {
                color: var(--black);
                opacity: 0.5;
                letter-spacing: normal;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            // button {
            //     position: absolute;
            //     right: 24px;
            //     background-color: transparent;
            //     padding: 0;
            // }
        }
        .btn-otp {
            outline: none;
            border: 2px solid var(--purple);
            background-color: var(--purple);
            border-radius: 8px;
            padding: 16px 20px 16px 24px;
            cursor: pointer;
            color: #fff;
            font-weight: bold;
            margin-top: 8px;
            width: 100%;
            line-height: normal;
        }
        .wrap-input {
            margin: 16px 0;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 0 16px;
        }
        .input-OTP {
            letter-spacing: 3px;
            font-size: 14px;
            width: 100%;
            font-weight: bold;
            padding: 16px 0px;
            border-radius: 8px;
            outline: none;
            line-height: normal;
            color: var(--purple);
            border: 2px solid rgba(128, 128, 128, 0.689);
            text-align: center;
            user-select: none;
            &::-webkit-input-placeholder {
                font-weight: bold;
                font-size: 14px;
                color: rgb(128, 128, 128);
                user-select: none;
            }
            &:focus {
                border-color: var(--purple);
                -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
                box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
                user-select: none;
            }
        }
        input::-webkit-input-placeholder {
            color: var(--black);
            opacity: 0.5;
            letter-spacing: normal;
            line-height: normal;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .btn-clear,
        .btn-very {
            line-height: normal;
            outline: none;
            border: 2px solid var(--purple);
            background-color: var(--purple);
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            color: #fff;
            font-weight: bold;
            margin-top: 8px;
            width: 100%;
        }
    }
}
@include mobile {
    .dialog-OTP {
        .dialog-OTP__wrap {
            max-width: unset;
            width: 100%;
        }
        padding: 0 24px;
        .dialog-OTP__content {
            .wrap-input {
                margin: 16px 0;
                .input-OTP {
                    padding: 8px 0;
                    flex-shrink: 0;
                }
            }
            .btn-very,
            .btn-clear {
                padding: 8px 0;
                margin: 0;
            }
        }
    }
}
