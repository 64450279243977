.dialog-alert {
    max-width: 360px;
    padding: 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .title-alert {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 8px;
    }
    .title {
        text-align: center;
        display: inline-block;
        font-weight: bold;
    }
    &__img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__btn {
        margin: 0 auto;
        margin-top: 16px;
        .btn-success {
            background-color: var(--purple);
            border-radius: 16px;
            width: max-content;
            cursor: pointer;
            padding: 8px 16px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: calc(100vw - 86px);
    }
}
