.endow {
    background-color: #fff;
    &-wrapper {
        margin-top: 16px;
        padding-bottom: 24px;
    }
    .promo-wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }
    .promo-skeleton {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        width: 100%;
    }
    .promo-skeleton__item {
        border-radius: 16px;
        overflow: hidden;
        height: 126px;
    }
    &-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 24px;
        background-color: rgba(0, 0, 0, 0.727);
        width: 100%;
        height: 100%;
        z-index: 2;

        &__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p {
                white-space: nowrap;
                font-size: 16px;
                color: #fff;
            }
        }
    }
    &-item {
        height: 100%;
        z-index: 1;
        border: 1px solid rgba(184, 184, 184, 0.493);
        border-radius: 24px;
        cursor: pointer;
        overflow: hidden;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: flex;
        flex-direction: column;
        &__img {
            height: 134px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            .item-name {
                padding: 16px 16px 0 16px;
                font-weight: bold;
                font-size: 18px;
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .item-price {
                padding: 0 16px 8px 16px;

                p {
                    text-decoration: line-through;
                    color: gray;
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: capitalize;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                }
            }
            .item-special {
                color: var(--red);
                font-size: 18px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .item-price__wrap {
                font-size: 14px;
                font-weight: bold;
                color: var(--red);
            }
            .item-wrap {
                line-height: 1.5;
            }
            .item-total {
                padding: 16px;
                border-top: 2px dashed rgba(0, 0, 0, 0.289);
                margin-top: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-weight: bold;
                .button-apply {
                    flex-shrink: 0;
                    p {
                        padding: 8px 16px;
                        border-radius: 16px;
                        background-color: var(--green);
                        color: #fff;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .promo-wrap {
            grid-template-columns: repeat(3, 1fr);
        }
        .promo-skeleton {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            width: 100%;
        }
        .promo-skeleton__item {
            border-radius: 16px;
            overflow: hidden;
            height: 340px;
        }
        .item-wrap {
            flex-direction: column-reverse;
            display: flex;
            gap: 8px 0;
            line-height: initial;
        }
        .item-total {
            align-items: flex-end;
        }
        .item-name {
            font-size: 16px;
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        .item-coupon {
            padding: unset;
        }
        .item-wrap {
            line-height: initial;
        }
        .item-name {
            font-size: 16px;
        }
        .item-special {
            font-size: 16px;
        }
        .item-price {
            padding-bottom: 0;
        }
        .endow-item__img {
            height: 180px;
        }
        .promo-wrap {
            grid-template-columns: 1fr;
            gap: 16px 0;
        }
        .endow-item {
            border-radius: 16px;
            display: flex;
            flex-direction: row;
            box-shadow: RGB(100 100 111 / 17%) 0px 0px 20px 11px;
        }
        .endow-item__img {
            width: 33.33%;
            height: unset;
            max-height: 203px;
        }
        .endow-item__content {
            width: 100%;
            border-left: 2px dashed rgba(0, 0, 0, 0.289);
        }
        .promo-skeleton {
            display: grid;
            grid-template-columns: 1fr;
            gap: 16px;
            width: 100%;
        }
        .promo-skeleton__item {
            border-radius: 16px;
            overflow: hidden;
            height: 184px;
        }
        .item-total {
            border: 0;
            padding-top: 0;
            align-items: flex-end;
        }
    }
}
.dialog-detail__endow {
    padding: 24px;
    max-height: calc(100vh - 48px);
    width: 700px;
    display: flex;
    .detail-img {
        width: 50%;
        max-height: 332px;
        flex-shrink: 0;
        border-radius: 16px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .detail-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
        gap: 2px 0;
        .detail-name {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 8px;
            text-transform: capitalize;
        }
        .detail-des {
            font-size: 14px;
            max-height: 126px;
            overflow-y: scroll;
            padding-right: 16px;
            &::-webkit-scrollbar {
                width: 3px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(112, 112, 112, 0.3);
                border-radius: 100px;
                overflow: hidden;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                overflow: hidden;
                background-color: rgb(170, 170, 170);
            }
        }

        .detail-discout {
            font-size: 16px;
            font-weight: bold;
            color: var(--red);
        }
        .detail-date {
            font-size: 14px;
            font-weight: bold;
        }
        .detail-bot {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .detail-btn {
                padding: 8px 16px;
                border-radius: 16px;
                background-color: var(--green);
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                cursor: pointer;
            }
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: calc(100vw - 48px);
        flex-direction: column;
        gap: 16px 0;
        padding: 16px;
        .detail-img {
            width: 100%;
            height: unset;
        }
        .detail-content {
            padding-left: unset;
            .detail-name {
                margin-bottom: unset;
                p {
                    font-size: 16px;
                }
            }
        }
        .detail-des {
            font-size: 14px;
        }
        .detail-remain {
            font-size: 14px;
        }
        .detail-discout {
            p {
                font-size: 16px;
            }
        }
        .detail-date {
            p {
                font-size: 14px;
            }
        }
        .detail-bot {
            .detail-quantity {
                font-size: 14px;
            }
            .detail-btn {
                font-size: 14px;
            }
        }
    }
}
