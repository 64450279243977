@font-face {
  font-family: "Quicksand-b";
  src: url("./Quicksand/Quicksand-Bold.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./Quicksand/Quicksand-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./Quicksand/Quicksand-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./Quicksand/Quicksand-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Quicksand";
  src: url("./Quicksand/Quicksand-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
