.checkin {
    .checkin-wrap {
        padding: 48px 0 24px 0;
        .checkin-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px 24px;
            position: relative;
        }

        .checkin-list__empty {
            text-align: center;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            .list-empty__img {
                transform: scale(0.8);
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .checkin-wrap {
            .checkin-list {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        .checkin-wrap {
            padding-top: 32px;
            .checkin-list {
                grid-template-columns: 1fr;
            }
            .checkin-list__empty {
                .list-empty__img {
                    transform: unset;
                }
                p {
                    margin-top: 16px;
                    white-space: nowrap;
                }
            }
        }
    }
}
.checkin-item {
    position: relative;
    padding: 24px;
    box-shadow: 0 8px 32px RGB(0 0 0 / 8%);
    border: 2px solid rgba(51, 51, 51, 0.1);
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    gap: 0 16px;
    align-items: center;
    .item-time,
    .item-length {
        font-weight: bold;
    }
    .checkin-item__top {
        position: absolute;
        top: -20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-status {
            padding: 8px 16px;
            border-radius: 8px;
            color: #fff;
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
            font-weight: bold;
        }
        .item-time {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .checkin-img {
        width: 100px;
        height: 100px;
        border-radius: 16px;
        overflow: hidden;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .checkin-content {
        display: flex;
        flex-direction: column;
        gap: 2px 0;
    }
    .item-info {
        font-weight: bold;
        font-size: 16px;
        text-transform: capitalize;
        line-height: normal;
    }
    .item-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding: 16px;
    }
}

.dialog-checkin {
    padding: 24px;
    max-height: calc(100vh - 48px);
    overflow-y: auto;
    max-width: 700px;
    width: 700px;

    .checkin-item {
        display: block;
    }
    .item-name {
        font-size: 16px;
        color: var(--purple);
        line-height: initial;
    }
    .item-duration {
        font-weight: bold;
    }
    .dialog-checkin__title {
        font-size: 16px;
        font-weight: bold;
        color: var(--purple);
        line-height: 36px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.241);
    }
    .dialog-checkin__title:nth-child(4) {
        margin-top: 8px;
    }
    .dialog-checkin__org {
        padding-top: 16px;
        display: flex;
        gap: 16px;
        .org-img {
            width: 100px;
            height: 100px;
            border-radius: 16px;
            overflow: hidden;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .org-content {
            display: flex;
            flex-direction: column;
            gap: 4px 0;
            margin-top: 8px;
            .item-name,
            .item-address {
                font-weight: bold;
                line-height: normal;
            }
        }
    }
    .dialog-checkin__booking {
        margin-top: 32px;
    }
    .checkin-service__list {
        display: flex;
        flex-direction: column;
        .checkin-service__item {
            display: flex;
            gap: 0 8px;
            &:not(:first-child) {
                padding-top: 16px;
            }
            &:not(:last-child) {
                padding-bottom: 16px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid rgba(163, 162, 162, 0.13);
            }
            .service-item__img {
                width: 100px;
                height: 100px;
                border-radius: 16px;
                overflow: hidden;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .service-item__content {
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 8px 0 0 8px;
                gap: 2px 0;
                .item-name {
                    font-size: 16px;
                    font-weight: bold;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                }
                .item-price {
                    font-weight: bold;
                }
            }
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: calc(100vw - 48px);
        padding: 16px;

        .dialog-checkin__info {
            .info-left {
                width: 33.33%;
            }
            .info-right {
                width: 66.66%;
                p {
                    font-weight: bold;
                }
            }
        }
        .dialog-checkin__org {
            .org-content {
                margin-top: 0;
                padding-right: 10px;
                justify-content: center;
            }
        }
        .checkin-service__list {
            .checkin-service__item {
                padding: 0;
                .service-item__content {
                    justify-content: center;
                    gap: 4px 0;
                }
            }
        }
    }
}

.dialog-cart {
    height: 100%;
    width: 100%;
    border-radius: unset;
    background-color: #fff;
    .service-cart {
        display: block;
        width: 100%;
        height: 100%;
    }
    .service-cart__title .service-cart_btnclose {
        display: block;
        position: absolute;
        padding: 16px;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
    }

    .service-cart__title {
        position: relative;
        padding: 16px;
    }
    .service-cart__list {
        padding-left: 16px;
        padding-right: 16px;
    }
}
