// MIXINS
@mixin tablet {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        @content;
    }
}
.service {
    // height: 100vh;
    height: 100%;
    background-color: var(--purple);
    position: relative;

    &-wrap {
        display: flex;
        margin-top: 16px;
        gap: 0 16px;
        overflow: hidden;
        padding-bottom: 16px;
        height: calc(100vh - 76px);
    }
    &-tab {
        width: 66.66%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        height: 100%;
        &__list {
            z-index: 999;
            display: flex;
            justify-content: center;
            width: 100%;
            gap: 0 32px;
            background-color: #fff;
            border-radius: 16px 16px 0 0;
            box-shadow: rgba(149, 157, 165, 0.109) 0px 8px 24px;
        }
        &__item {
            font-size: 16px;
            width: max-content;
            height: 100%;
            background-color: #fff;
            cursor: pointer;
            user-select: none;
            :hover {
                color: var(--purple);
            }
            p {
                line-height: 3;
                text-align: center;
                font-weight: bold;
            }
        }
        &__item:nth-child(1) {
            border-radius: 16px 0 0 0;
        }
        &__item:nth-child(3) {
            border-radius: 0 16px 0 0;
        }
        &__content {
            overflow: hidden;
            background-color: #fff;
            height: calc(100vh - 132px);
            border-radius: 0 0 16px 16px;
            padding: 16px 0 24px 0;
            .content-service {
                height: 100%;
                .content-cates {
                    display: flex;
                    align-items: center;
                    gap: 0 16px;
                    overflow-x: scroll;
                    padding: 0px 24px 0 24px;
                    .content-cate p {
                        width: max-content;
                        padding: 8px 16px;
                        border-radius: 16px;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        cursor: pointer;
                    }
                }
                .content-cates::-webkit-scrollbar {
                    display: none;
                }
                .content-service__title {
                    display: flex;
                    padding: 16px 32px 16px 24px;
                    border-bottom: 1px solid var(--bgGray);

                    p {
                        font-size: 14px;
                        line-height: 16px;
                        color: gray;
                    }
                    p:nth-child(1) {
                        width: 60%;
                    }
                    p:nth-child(2) {
                        width: 20%;
                    }
                    p:nth-child(3) {
                        width: 20%;
                        text-align: center;
                    }
                }
                .content-service__list {
                    padding: 8px 16px 150px 16px;
                    .content-service__item {
                        width: 100%;
                        cursor: pointer;
                        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
                        // margin-top: 16px;
                        padding: 8px;
                        display: flex;
                        border-radius: 8px;
                        align-items: center;
                        font-size: 14px;
                        .item-top {
                            width: 60%;
                            display: flex;
                            align-items: center;
                            gap: 0 8px;
                            .item-img {
                                width: 50px;
                                height: 50px;
                                overflow: hidden;
                                border-radius: 8px;
                                flex-shrink: 0;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .item-name {
                                font-size: 14px;
                                line-height: 16px;
                                text-transform: capitalize;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-word;
                                padding-right: 16px;
                                font-weight: bold;
                            }
                        }
                        .item-info {
                            width: 40%;
                            display: flex;
                            font-weight: bold;
                            align-items: center;
                            .item-time {
                                width: 100%;
                                font-weight: bold;
                            }
                            .item-price {
                                font-weight: bold;
                                display: flex;
                                gap: 0 16px;
                                width: 100%;
                                justify-content: flex-end;
                                align-items: center;
                                .price-wrap {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-end;
                                }
                                &__img {
                                    flex-shrink: 0;
                                    width: 20px;
                                    height: 20px;
                                    cursor: pointer;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                        &:hover {
                            background-color: var(--bgGray);
                            transition: 0.3s all
                                cubic-bezier(0.075, 0.82, 0.165, 1);
                        }
                    }
                }
                .content-service__list::-webkit-scrollbar {
                    display: none;
                }
            }
            // close tab service

            // tab product
            .content-product {
                height: 100%;
                .content-product__list {
                    display: grid;
                    gap: 16px;
                    grid-template-columns: repeat(4, 1fr);
                    width: 100%;
                    padding: 0 24px 120px 24px;
                    .content-product__item {
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        border: 1px solid rgba(128, 128, 128, 0.514);
                        border-radius: 16px;
                        height: 100%;
                        .item-img {
                            width: 100%;
                            border-radius: 16px 16px 0 0;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 120px;
                                object-fit: cover;
                            }
                        }
                        .item-content {
                            padding: 16px;
                            flex-direction: column;
                            display: flex;
                            flex-shrink: 0;
                            flex: 1;
                            .item-name {
                                p {
                                    text-transform: capitalize;
                                    font-size: 14px;
                                    font-weight: bold;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-word;
                                    line-height: normal;
                                }
                            }

                            .item-bot {
                                margin-top: auto;
                                display: flex;
                                flex-direction: column;
                                padding-top: 4px;
                                .item-bot__price {
                                    font-size: 14px;
                                    font-weight: bold;
                                }
                                .item-bot__special {
                                    margin-top: auto;
                                    font-size: 14px;
                                    color: var(--red);
                                    font-weight: bold;
                                }
                                &__btn {
                                    width: 20px;
                                    height: 20px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }

                .content-cates {
                    display: flex;
                    align-items: center;
                    gap: 0 16px;
                    overflow-x: scroll;
                    padding: 0 24px 16px 24px;
                    .content-cate p {
                        width: max-content;
                        padding: 8px 16px;
                        border-radius: 16px;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        cursor: pointer;
                    }
                }
                .content-cates::-webkit-scrollbar {
                    display: none;
                }
            }
            // close tab product

            // tab combo
            .content-combo {
                height: 100%;
                .combo-list {
                    display: grid;
                    gap: 16px;
                    grid-template-columns: repeat(4, 1fr);
                    padding: 0 24px 48px 24px;
                    .combo-item {
                        cursor: pointer;
                        border-radius: 16px;
                        flex-direction: column;
                        display: flex;
                        border: 1px solid rgba(128, 128, 128, 0.514);
                        .item-img {
                            height: 120px;
                            border-radius: 16px 16px 0 0;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .item-content {
                            padding: 16px;
                            border-radius: 16px;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            gap: 2px 0;
                            .item-expiry {
                                font-size: 14px;
                                font-weight: bold;
                                color: var(--purple);
                            }
                            .item-name {
                                font-size: 14px;
                                font-weight: bold;
                                text-transform: capitalize;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-word;
                                line-height: normal;
                            }
                            .item-footer {
                                margin-top: auto;
                                .item-price__wrap {
                                    display: flex;
                                    gap: 0 8px;
                                    flex-direction: column;
                                    .item-price {
                                        font-size: 14px;
                                        font-weight: bold;
                                        color: var(--purple);
                                    }
                                    .item-price__special {
                                        color: var(--red);
                                        font-size: 12px;
                                    }
                                }
                                .item-total {
                                    font-size: 14px;
                                    color: var(--black);
                                }
                                .item-bot {
                                    margin-top: 4px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    img {
                                        cursor: pointer;
                                    }
                                }
                                .item-bot__btn {
                                    width: 20px;
                                    height: 20px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // close tab combo
        }
    }
    // cart
    &-cart {
        width: calc(100% - 66.66%);
        border-radius: 16px 16px 0 0;
        background-color: #fff;
        overflow: hidden;
        border-radius: 16px;
        position: relative;
        &__title {
            font-size: 16px;
            color: var(--purple);
            font-weight: bold;
            padding: 16px 0 8px 0;
            text-align: center;
            .service-cart_btnclose {
                display: none;
            }
        }
        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 24px;
            p {
                color: gray;
                font-size: 14px;
                line-height: 16px;
            }
        }
        &__list {
            padding: 0 24px 260px 24px;
            overflow-y: scroll;
            height: 100%;
            // height: calc(100% - $height-total);
            .cart-list__empty {
                padding: 16px;
                margin-top: 16px;
            }
            .list-empty__title {
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                color: gray;
                margin-top: 16px;
            }
        }
        &__list::-webkit-scrollbar {
            display: none;
        }
        &__item {
            margin-top: 16px;
            padding-bottom: 16px;
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            width: 100%;
            .item-left p {
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .item-right p {
                width: 100px;
                text-align: end;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }

            .item-info {
                width: 100%;
                display: flex;
                margin-top: 8px;
                gap: 0 8px;
                align-items: center;
                justify-content: space-between;
            }
            .item-img {
                width: 50px;
                height: 50px;
                border-radius: 8px;
                overflow: hidden;
                flex-shrink: 0;
                margin-right: 10px;
            }
            .item-img img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .item-price {
                color: var(--purple);
                margin-top: 4px;
            }
            .item-option {
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .item-amount {
                display: flex;
                gap: 0 16px;
                align-items: center;
            }
            .item-delete {
                width: 20px;
                height: 20px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        &__item:not(:last-child) {
            border-bottom: 1px solid var(--bgGray);
        }
        &__payment {
            position: absolute;
            border-top: 1px solid var(--bgGray);
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: 2;
            padding: 16px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px 0;
            justify-content: center;
            background-color: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 8px 30px 0px;
            .payment-endow {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                p:nth-child(1) {
                    font-weight: bold;
                    font-size: 14px;
                }
                p:nth-child(2) {
                    font-weight: bold;
                    font-size: 18px;
                }
            }
            .payment-all {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 0 8px;
                p:first-child {
                    color: var(--purple);
                    font-weight: bold;
                    font-size: 14px;
                }
                &__choose {
                    display: flex;
                    align-items: center;
                    gap: 0 8px;
                    cursor: pointer;
                }
                p:nth-child(2) {
                    padding: 8px 16px;
                    border-radius: 16px;
                    background-color: var(--purple);
                    color: #fff;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    cursor: pointer;
                    flex-shrink: 0;
                }
                &__btn {
                    width: 20px;
                    height: 20px;
                    flex-shrink: 0;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    // close cart

    // reponsive tablet
    @include tablet {
        .service-cart {
            display: none;
        }
        .service-tab {
            width: 100%;
            .service-tab__content {
                padding: 16px 0;
                .content-service {
                    .content-cates {
                        gap: 0;
                        padding: 0 24px 0 8px;
                    }
                    .content-cate {
                        margin-bottom: 16px;
                        margin-left: 16px;
                    }
                    .content-service__list {
                        padding: 0 24px 150px 24px;
                    }
                    .content-service__title {
                        padding: 0 24px 16px 24px;
                    }
                }
                .content-product {
                    padding: 0 0 50px 0;
                    .content-product__list {
                        padding: 0 24px 80px 24px;
                        gap: 24px;
                    }
                }
                .content-combo {
                    margin-top: 8px;
                    .combo-list {
                        grid-template-columns: repeat(3, 1fr);
                        margin: 0;
                        padding: 0 24px 64px 24px;
                    }
                }
                .content-product__title {
                    display: none;
                }
                .content-product__list {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 16px;
                    padding-right: 16px;
                    padding-left: 16px;
                    height: unset;
                    padding-bottom: 64px;
                    .content-product__item {
                        flex-direction: column;
                        align-items: unset;
                        gap: 4px 0;
                        background-color: #fff;
                        padding: 0;
                        margin-top: 0;
                        border: 1px solid #80808047;
                        border-radius: 16px;
                        .item-info {
                            width: 100%;
                            flex-direction: column;
                            align-items: unset;
                            .item-img {
                                width: 100%;
                                height: 120px;
                                border-radius: 16px 16px 0 0;
                            }
                            .item-name {
                                margin-top: 16px;
                                padding: 0 16px;
                            }
                        }
                        .item-time {
                            width: 100%;
                            padding: 0 16px;
                        }
                        .item-price {
                            padding: 0 16px 16px 16px;
                            width: 100%;
                            justify-content: space-between;
                            margin-top: auto;
                            .price-wrap {
                                align-items: unset;
                            }
                        }
                    }
                }
            }
        }
    }
    @include mobile {
        flex-direction: column;
        display: flex;
        overflow-y: hidden;
        .service-cart {
            display: none;
        }

        .service-wrap {
            // padding: 0;
            height: 100%;
        }
        .service-tab {
            width: 100%;
            .service-tab__list {
                justify-content: space-evenly;
                gap: 0;
            }
            .service-tab__content {
                padding: 0 0 16px 0;
                .content-service {
                    overflow: hidden;
                    .content-cates {
                        gap: 0;
                        padding: 16px 16px 0 0;
                    }
                    .content-cate {
                        margin-bottom: 16px;
                        margin-left: 16px;
                    }
                    .content-service__list {
                        padding: 0 16px 100px 16px;
                        display: grid;
                        gap: 16px;
                        grid-template-columns: repeat(2, 1fr);
                        .content-service__item {
                            flex-direction: column;
                            align-items: unset;
                            gap: 4px 0;
                            background-color: #fff;
                            padding: 0;
                            margin-top: 0;
                            border: 1px solid #80808047;
                            border-radius: 16px;
                            .item-top {
                                width: 100%;
                                flex-direction: column;
                                align-items: unset;
                                .item-img {
                                    width: 100%;
                                    height: 120px;
                                    border-radius: 16px 16px 0 0;
                                }
                                .item-name {
                                    margin-top: 8px;
                                    padding: 0 8px;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-word;
                                }
                            }
                            .item-info {
                                width: 100%;
                                flex-direction: column;
                                align-items: unset;
                                margin-top: auto;
                            }
                            .item-time {
                                width: 100%;
                                padding: 0 8px;
                            }
                            .item-price {
                                padding: 0 8px 8px 8px;
                                width: 100%;
                                justify-content: space-between;
                                margin-top: auto;
                                align-items: flex-end;
                                .price-wrap {
                                    align-items: unset;
                                }
                            }
                        }
                    }
                }

                .content-product {
                    padding: 16px 0;
                    .content-product__list {
                        grid-template-columns: repeat(2, 1fr);
                        padding: 0px 16px 48px 16px;
                    }
                    .content-cates {
                        padding: 0 16px 16px 16px;
                    }
                    .content-cate p {
                        text-transform: capitalize;
                    }
                    .content-product__item {
                        height: 100%;
                        .item-content {
                            padding: 8px;
                        }
                        .item-img {
                            height: 120px;
                        }
                        .item-name {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-word;
                        }
                    }
                }

                .content-combo {
                    .combo-list {
                        grid-template-columns: repeat(2, 1fr);
                        margin: 0;
                        padding: 16px 16px 48px 16px;
                    }
                    .item-content {
                        padding: 8px;
                    }
                    .item-img {
                        height: 120px;
                    }
                }
                .content-service__title {
                    display: none;
                }
            }
        }
    }
}
.amount-de,
.amount-ce,
.item-delete {
    cursor: pointer;
}

// dialog detail
.dialog-detail {
    display: flex;
    padding: 24px;
    height: max-content;
    max-height: calc(100vh - 24px);
    position: relative;
    width: 600px;
    &__close {
        z-index: 999;
        position: absolute;
        top: -15px;
        right: -15px;
        width: 30px;
        height: 30px;
        background-color: var(--bgWhite);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 80%;
            height: 80%;
            object-fit: cover;
            transform: rotate(45deg);
            transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
            &:hover {
                transform: rotate(135deg);
                transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
        }
    }
    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 24px;
    }
    &__img {
        width: 40%;
        height: 100%;
        flex-shrink: 0;
        border-radius: 16px;
        overflow: hidden;
        img {
            width: 100%;
            height: unset;
            object-fit: cover;
        }
    }
    .content-name {
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 8px;
        text-transform: capitalize;
    }
    .content-price {
        p {
            font-size: 14px;
            font-weight: bold;
            color: gray;
        }
    }
    .content-price__special {
        font-size: 18px;
        font-weight: bold;
        color: var(--red);
    }
    .content-time {
        margin: 4px 0;
    }
    .content-detail {
        max-height: 140px;
        overflow-y: auto;
        padding-right: 4px;
        /* width */
        &::-webkit-scrollbar {
            width: 4px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 4px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #888;
            border-radius: 4px;
        }
    }
    .content-time,
    .content-detail {
        font-size: 14px;
        color: gray;
        span {
            font-weight: bold;
            color: var(--black);
        }
    }
    @include tablet {
        width: 600px;
        &__img {
            width: 50%;
            height: unset;
            flex-shrink: 0;
            border-radius: 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content-detail {
            max-height: 90px;
        }
    }
    @include mobile {
        padding: 16px;
        width: calc(100vw - 48px);
        flex-direction: column;
        gap: 16px 0;
        .dialog-detail__img {
            width: 100%;
            height: unset;
        }
        .dialog-detail__content {
            padding-left: 0;
            // padding: 0 16px;
            .content-name {
                font-size: 16px;
            }
        }
    }
}

// cart
.content-addcart {
    padding-top: 24px;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        padding-top: 16px;
    }
    .item-amount {
        display: flex;
        align-items: center;
        gap: 0 10px;
    }

    .btn-addcart {
        padding: 8px 16px;
        border-radius: 16px;
        background-color: var(--purple);
        cursor: pointer;
        p {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
        }
    }
}

// dialog combo
.dialog-detail__combo {
    padding: 24px;
    width: 400px;
    max-height: calc(100vh - 48px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(132, 132, 132, 0.574);
        border-radius: 100px;
        overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(120, 120, 120, 0.455);
        border-radius: 100px;
        overflow: hidden;
    }
    .detail-content {
        display: flex;
        flex-direction: column;
        margin-bottom: 70px;
        .detail-top {
            display: flex;
            border-radius: 16px;
            position: relative;
            gap: 16px;
            .detail-expiry {
                font-size: 14px;
                font-weight: bold;
                color: var(--red);
            }
            .detail-img {
                width: 50%;
                flex-shrink: 0;
                height: unset;
                border-radius: 16px;
                overflow: hidden;
                margin-bottom: 8px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .detail-name {
                font-size: 16px;
                font-weight: bold;
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
        }
        .name-cate {
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
            position: absolute;
            background-color: var(--purple);
            color: white;
            border-radius: 8px;
            padding: 8px 16px;
            top: 0;
            transform: translateY(-50%);
        }

        .detail-cate {
            padding-top: 32px;
            &__item {
                padding: 16px;
                border-radius: 16px;
                background-color: rgba(209, 209, 209, 0.322);
                margin-bottom: 8px;
            }

            .item-service {
                padding: 16px;
                box-shadow: 0 8px 32px RGB(0 0 0 / 8%);
                border: 1px solid rgba(51, 51, 51, 0.1);
                border-radius: 16px;
                position: relative;
            }
            .item-name {
                margin-top: 8px;
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            .item-price {
                font-size: 14px;
                font-weight: bold;
                color: var(--red);
            }
            .item-quantity {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .content-addcart {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 16px 24px;
        background-color: #fff;
        border-radius: 0 0 16px 16px;
        border: 1px solid rgba(128, 128, 128, 0.268);
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        width: calc(100vw - 48px);
        padding: 16px;
        .detail-content {
            .detail-top {
                flex-direction: column;
                gap: 0;
                .detail-img {
                    width: 100%;
                    margin-bottom: 16px;
                }
            }
        }
    }
}
.btn-delete {
    padding: 8px 16px;
    border-radius: 16px;
    background-color: var(--purple);
    color: #fff;
    font-weight: bold;
    width: max-content;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 auto;
    margin-top: 16px;
    p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }
}
