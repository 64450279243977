.btn-cart {
    display: none;
    position: fixed;
    right: 24px;
    bottom: 32px;
    padding: 10px;
    background-color: var(--purple);
    border-radius: 100px;
    z-index: 1000;
    img {
        width: 20px;
        height: 20px;
    }
    p {
        position: absolute;
        top: -4px;
        right: -4px;
        background-color: var(--red);
        width: 20px;
        height: 20px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        display: block;
        right: 46px;
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        display: block;
        right: 24px;
        bottom: 30px;
    }
}
