.scan {
    width: 100%;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .scan-img {
        width: 300px;
        margin: 0 auto;
        margin-bottom: 24px;
    }
    .scan-text {
        text-align: center;
        font-weight: bold;
        p {
            font-size: 20px;
            line-height: 2;
        }
        span {
            font-size: 14px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .scan-img {
        width: 200px;
    }
}
