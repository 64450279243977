.header {
    height: 60px;
    background-color: var(--bgWhite);
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    &-back {
        cursor: pointer;
        padding: 16px;
        position: absolute;
    }
    &-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: var(--purple);
        font-weight: bold;
    }
    &-search {
        position: absolute;
        right: 0;
        padding: 16px;
        display: none;
    }
    &-search__input {
        position: absolute;
        right: 16px;
        padding: 4px 8px;
        border: 2px solid var(--purple);
        border-radius: 8px;
        overflow: hidden;
        background-color: white;
        input {
            cursor: pointer;
            background-color: transparent;
            &::-webkit-input-placeholder {
                font-weight: bold;
                font-size: 14px;
                color: rgb(128, 128, 128);
            }
        }
        img {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        height: 50px;
        &-title {
            font-size: 16px;
        }
        &-back {
            left: 0;
        }
        &-search {
            position: absolute;
            right: 0;
            padding: 16px;
            display: block;
        }
        &-search__input {
            display: none;
        }
    }
}

.dialog-search {
    padding: 24px;
    background-color: var(--bgWhite);
    width: 400px;
    max-width: 500px;
    border-radius: 16px;
    overflow: hidden;

    .dialog-search__header {
        margin-bottom: 8px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px 0;
        .dialog-search__back {
            margin-left: -8px;
            flex-shrink: 0;
            display: none;
            padding: 8px;
            width: max-content;
        }
        .dialog-search__input {
            width: 100%;
            position: relative;
            input {
                border-radius: 8px;
                overflow: hidden;
                padding: 8px 16px 8px 40px;
                line-height: normal;
                font-size: 14px;
                width: 100%;
                font-weight: bold;
                outline: none;
                color: var(--purple);
                border: 2px solid rgb(128, 128, 128);
                &::-webkit-input-placeholder {
                    font-weight: bold;
                    font-size: 14px;
                    color: rgb(128, 128, 128);
                }
                &:focus {
                    border-color: var(--purple);
                    -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
                    box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
                }
            }
            input::-webkit-input-placeholder {
                color: var(--black);
                opacity: 0.5;
                letter-spacing: normal;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .icon-search {
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
            }
            .icon-x {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
            }
        }
    }
    .dialog-search__option {
        margin-top: 8px;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .option-left {
            font-size: 16px;
            font-weight: bold;
        }
        .option-right {
            font-size: 14px;
            font-weight: bold;
            color: var(--purple);
            cursor: pointer;
        }
    }
    .dialog-search__body {
        max-height: 80vh;
        min-height: max-content;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        .dialog-search__list {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 16px 0;
            &:nth-child(2) {
                padding-top: 4px;
            }
            .dialog-search__title {
                font-weight: bold;
                font-size: 16px;
                color: var(--purple);
                padding-bottom: 4px;
                border-bottom: 1px solid var(--purple);
            }
            .dialog-search__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                gap: 0 16px;
                .item-left {
                    align-items: center;
                    display: flex;
                    gap: 0 16px;
                    .item-left__img {
                        flex-shrink: 0;
                        width: 50px;
                        height: 50px;
                        border-radius: 8px;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .item-left__title {
                        font-weight: bold;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-word;
                        text-transform: capitalize;
                    }
                }
                .item-right {
                    cursor: pointer;
                    flex-shrink: 0;
                    padding: 8px;
                    margin: -8px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        height: unset;
        max-height: unset;
        width: unset;
        padding: 16px;
        border-radius: unset;
        .dialog-detail__close {
            display: none;
        }
        .dialog-search__back {
            display: block;
        }
        .dialog-search__header {
            .dialog-search__back {
                display: block;
            }
            .dialog-search__input {
                margin-left: 8px;
            }
        }
        .dialog-search__body {
            max-height: 100%;
            height: 100vh;
            padding-bottom: 164px;
        }
    }
}
.dialog-alert {
    align-items: center;
    .alert__img {
        width: 50px;
        height: 50px;
        margin-bottom: 8px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
