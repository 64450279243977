.evaluate {
    padding-bottom: 36px;
    &-title {
        h1 {
            font-family: Quicksand;
            font-size: 16px;
            font-weight: 400;
            color: var(--purple);
            text-align: center;
            margin-top: 16px;
        }
        h3 {
            font-size: 16px;
            font-weight: 400;
            width: 800px;
            margin: 0 auto;
            text-align: center;
        }
    }
    &-img {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 16px;
        box-shadow: rgba(148, 147, 147, 0.2) 0px 7px 29px 0px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-rating {
        max-width: 800px;
        margin: 0 auto;
        &__start {
            margin-bottom: 8px;
        }
        .rating-item {
            display: flex;
            justify-content: space-between;
            gap: 8px 0;
            p {
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    &-rating__staff {
        padding-top: 8px;
        max-width: 800px;
        margin: 0 auto;
        .evaluate-rating__title {
            border-top: 1px solid rgba(128, 128, 128, 0.35);
            font-weight: bold;
            padding: 16px 0;
        }
        .list-staff {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            width: 100%;
            margin-bottom: 16px;
            gap: 16px;
        }
        .item-staff__img {
            width: 50px;
            height: 50px;
            border-radius: 100px;
            overflow: hidden;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .item-staff {
            align-items: center;
            width: 100%;
            display: flex;
            gap: 0 8px;
        }
        .item-staff__name {
            font-weight: 14px;
            text-transform: capitalize;
            font-weight: bold;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }
    &-input {
        max-width: 800px;
        margin: 0 auto;
        input {
            width: 100%;
            padding: 16px 24px;
            border: 1px solid var(--purple);
            border-radius: 8px;
            outline: 0;
            margin-top: 16px;
            &::-webkit-input-placeholder {
                font-weight: bold;
                font-size: 16px;
                color: rgb(128, 128, 128);
            }
            &:focus {
                border-color: var(--purple);
                -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
                box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
            }
        }
    }
    &-btn {
        width: max-content;
        margin: 0 auto;
        padding-top: 16px;

        p {
            width: max-content;
            padding: 8px 16px;
            border-radius: 16px;
            background-color: var(--green);
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
            margin: 0 auto;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        .evaluate-wrapper {
            max-width: 600px;
            margin: 0 auto;
            .evaluate-title {
                h3 {
                    max-width: 600px;
                }
            }
            .evaluate-rating {
                margin-top: 16px;
            }
            .list-staff {
                grid-template-columns: repeat(3, 1fr);
                gap: 16px;
            }
        }
    }
    @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding-bottom: 24px;
        .evaluate-wrapper {
            max-width: 600px;
            margin: 0 auto;
            .evaluate-title {
                h1 {
                    font-size: 16px;
                }
                h3 {
                    margin-top: unset;
                    width: unset;
                    line-height: 1.5;
                    font-size: 14px;
                    display: none;
                }
            }
            .evaluate-rating {
                margin-top: 16px;
            }
            .evaluate-input {
                input {
                    font-size: 14px;
                    padding: 16px;
                    border: 2px solid rgb(128, 128, 128);
                    &::-webkit-input-placeholder {
                        font-weight: bold;
                        font-size: 16px;
                        color: rgb(128, 128, 128);
                    }
                    &:focus {
                        border-color: var(--purple);
                        -webkit-box-shadow: 0 0 0 4px rgba(222, 225, 255, 0.712);
                        box-shadow: 0 0 0 4px rgba(227, 222, 255, 0.712);
                    }
                }
            }
            .list-staff {
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;
                margin-bottom: unset;
            }
            .item-staff {
                gap: 0 4px;
                justify-content: unset;
            }
            .evaluate-btn {
                padding-top: 24px;
            }
        }
    }
}

.dialog-confirm {
    &__wrapper {
        padding: 24px;
    }
    &__img {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__desc {
        text-align: center;
        p:nth-child(1) {
            font-size: 20px;
            margin-bottom: 8px;
        }
        p {
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
        }
        p:nth-child(3) {
            margin-top: 8px;
        }
    }
    &__btn {
        margin-top: 16px;
        p {
            width: max-content;
            padding: 8px 16px;
            border-radius: 16px;
            background-color: var(--purple);
            color: #fff;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
            margin: 0 auto;
        }
    }
}
